// flex classes are missing from old bootstrap

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}


.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.mb-2 { margin-bottom:.5rem!important; }
.mt-3 { margin-top:.75rem!important; }
.m-3 { margin:.75rem!important; }

.rounded-circle {
  border-radius: 50%;
}

.story-layout-sidebar {
  margin-top: 8px;
}

.story-list {
  padding-bottom: 5px;

  &.loading {
    opacity: 0.3;
  }

  .slick-list {
    padding: 3px;
    margin-right: 5px;
  }

  .story-list-item {
    padding-right: 1em;
    padding-bottom: 10px;


    .card {
      height: 250px;
      width: 160px;
      overflow: hidden;
      position: relative;
      border: 1px solid #888888;
      filter: drop-shadow(#888888 0px 10px 0px);
      border-radius: 10px;

      .card-body {
        background-color: #ffffff;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 250px;
      }

      .user-name {
        position: absolute;
        bottom: 0;
        left: 1em;
        width: 100%;
        text-overflow: ellipsis;
        color: #ffffff;
        filter: drop-shadow(#000000 0px 0px 2px);
      }

      .user-picture, .add-button {
        width: 60px;
        height: 60px;
        border-radius: 40px;
        margin-right: 10px;
        filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 2px);
        border: 2px solid rgba(255, 255, 255, 0.8);
        background: #ffffff;
        background-position: center;
        background-size: cover;
      }

      .user-picture {
        position: absolute;
        top: 1em;
        left: 1em;
      }

      .card-footer {
        position: absolute;
        bottom: 0;
        background-color: #FFFFFF;
        width: 100%;
        height: 60px;
        padding-top: 30px;

        .add-button {
          //background-image: url('./img/plus-icon.png');
          left: 50px;
          top: -30px;
          position: absolute;

          i {
            color: #000000;
            font-size: 64px;
            margin-top: -4px;
            margin-left: -5px;
          }
        }
        .add-text {
          color: deeppink;
          width: 100%;
          text-align: center;
          font-weight: bold;
        }
      }
    }

    &.story-list-item--create-item {
      .card-body {
        height: 200px;
      }
    }
  }
}


.story-presentation-wrapper {
  button.closeButton {
    background-color: transparent;
    color: #FFFFFF;
    filter: drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 3px);
    border: 0;
    min-width: 3em;
  }

  .controlsContainer {
    position: absolute;
    top: 2em;
    right: 1em;
    z-index: 1002;
  }

  &.story-presentation-wrapper--fullscreen {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
  }
}


.stories-component-wrapper {
  > div:nth-child(1) {
    > div:nth-child(2) { // renderer wrapper
      > div:nth-child(1) { // renderer inner <div>
        width: 100%; // stretch it to 100% and position the media to the center
        text-align: center;
      }
    }
  }
}

.story--user-content {
  font-size: 30px;
  text-align: center;
  display: block;
  top: 0;
  height: 100%;
  color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.9) 0px 0px 3px);
}


.story-editor {
  height: 80%;
  //z-index: 900;

  h1 {
    text-align: center;
  }

  .story-preview { //editor
    position: relative;

    .story--user-content {
      background-color: rgba(100, 100, 100, 0.2);

      border: 1px inset rgba(0, 0, 0, 0);
      margin: 3px;

      &:hover {
        border: 1px inset grey;
      }
    }

    &.story-preview--no-text .story--user-content {
      border: 1px inset gray;
      border-radius: 5px;
    }

    .stories-component-wrapper {
      > div:nth-child(1) {
        > div:nth-child(1) { // progress bar
          visibility: hidden; // hide it during editing
        }
      }
    }
  }


  .story-preview--loading {
    opacity: 0.3;
  }
}


#story_yt_player {
}
